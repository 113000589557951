<!--
 * @Author: sopen
 * @Date: 2021-06-30 10:04:37
 * @LastEditTime: 2021-08-02 12:09:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/views/home/usinfo.vue
-->
<template>
  <v-app>
    <inc-header></inc-header>
    <Vcode
      :show="vc.show"
      :canvasWidth="vc.width"
      @success="onSuccess"
      @close="onClose"
    />
    <v-container app fluid>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-alert outlined type="info" prominent border="left">
            用户手机号码可以接受短信、验证码及登录用途，请确认你的手机号码保持正常状态。假如更换手机号码，请及时操作。
          </v-alert>
        </v-col>
        <v-col cols="12" md="6" offset-md="3">
          <v-card v-if="!editWindow">
            <v-card-title class="brown lighten-3"> 我的手机号码 </v-card-title>
            <v-divider></v-divider>
            <v-card-actions class="py-4 text-h7">
              <v-icon class="text-h5 mr-4"> mdi-cellphone </v-icon>
              {{ mobileText }}
              <v-spacer></v-spacer>
              <v-btn
                class="brown lighten-1"
                dark
                small
                @click="editWindow = true"
                elevation="0"
              >
                <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
                更换
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card outlined v-else>
            <v-card-title> 更换手机号码 </v-card-title>
            <v-divider></v-divider>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form ref="form">
                <v-card-text>
                  <validation-provider
                    name="登录ID"
                    v-slot="{ errors }"
                    rules="required|digits:11"
                  >
                    <v-text-field
                      prepend-inner-icon="mdi-cellphone"
                      v-model="mobileSet"
                      :counter="11"
                      label="手机号码"
                      outlined
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="red" text @click="editWindow = false">取消</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green lighten-4"
                    @click="vc.show = true"
                    :disabled="invalid"
                  >
                    <v-icon left> mdi-send </v-icon>
                    发送验证码
                  </v-btn>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogCode.state" persistent max-width="360px">
        <v-card>
          <v-card-title class="py-2">
            请输入短信验证码
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogCode.state = false" :disabled="dialogCode.disabled">
              <v-icon>mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            {{ sendText }}
          </v-card-text>
          <v-card-text>
            <v-otp-input
              v-model="mobileCode"
              @finish="postMobile"
              length="4"
              width="300"
            ></v-otp-input>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar.state" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.state = false">
            <v-icon fab small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
import incHeader from "../inc-header.vue";
import Vcode from "vue-puzzle-vcode";

export default {
  components: { incHeader, Vcode },
  props: {
    mobile: String,
  },
  data() {
    return {
      mobileText: "",
      editWindow: false,
      mobileSet: "",
      mobileCode: "",
      sendText: "发送验证码",
      timeOut: 60,
      dialogCode: {
        state: false,
        disabled: true,
      },
      snackbar: {
        state: false,
        color: "red",
        text: "",
      },
      vc: {
        show: false,
        width: 460,
      },
      timeSet: "",
    };
  },
  mounted() {
    this.usinfo();
    let mobileSet = localStorage.getItem("mobileSet");
    let sendTime = localStorage.getItem("sendTime");
    if (sendTime != null) {
      this.sendTime();
      this.mobileSet = mobileSet;
      this.dialogCode.state = true;
    }
  },
  methods: {
    onSuccess() {
      this.vc.show = false; // 通过验证后，需要手动关闭模态框
      this.sendCode();
    },
    onClose() {
      this.vc.show = false;
    },
    usinfo() {
      let data = {
        module: "user",
        title: "info",
        data: {},
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        that.mobileText = res.data.user.phone;
      });
    },
    sendTime() {
      let timeOut = this.timeOut;
      let that = this;
      let d = new Date();
      let t = d.getTime();
      let sendTime = localStorage.getItem("sendTime");

      if (sendTime != "NaN") {
        sendTime = parseInt(sendTime);
      }

      if (isNaN(sendTime)) {
        let v = t + timeOut * 1000;
        localStorage.setItem("sendTime", v);
        this.sendTime();
      } else if (parseInt(t) > sendTime + 1000) {
        this.sendText = "发送验证码";
        localStorage.removeItem("sendTime");
        this.dialogCode.disabled = false;
        clearTimeout(this.timeSet);
      } else {
        let no = Math.ceil((sendTime - t) / 1000);
        this.sendText = "发送中(" + no + ")...";
        this.timeSet = setTimeout(function () {
          that.sendTime();
        }, 1000);
        return false;
      }
    },
    sendCode() {
      localStorage.setItem("mobile", this.mobileSet);
      let data = {
        module: "usinfo",
        title: "mobilecode",
        data: {
          mobile: this.mobileSet,
          s: 600,
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.usinfo && res.data.usinfo.errcode == 0) {
          that.dialogCode = {
            state: true,
            disabled: true,
          };
          that.sendTime();
        }

        that.snackbar = {
          state: true,
          color: res.data.usinfo.errcode == 0 ? "green" : "red",
          text: res.data.usinfo.errmsg,
        };
      });
    },
    editMobile() {
      this.editWindow = true;
    },
    postMobile() {
      let mobileSet = localStorage.getItem("mobile");
      let data = {
        module: "usinfo",
        title: "mobileset",
        data: {
          mobile: mobileSet,
          mobilecode: this.mobileCode,
          type: "update",
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.usinfo.errcode == 0) {
          localStorage.removeItem("sendTime");
          localStorage.removeItem("mobile");
          clearTimeout(that.timeSet);
          that.usinfo();
          that.dialogCode.state = false;
          that.editWindow = false;
          that.snackbar.state = true;
          that.snackbar.color = "green";
          that.snackbar.text = "修改成功";
        } else {
          that.snackbar.state = true;
          that.snackbar.color = "red";
          that.snackbar.text = res.data.usinfo.errmsg;
        }
      });
    },
  },
  watch: {},
};
</script>
